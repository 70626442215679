import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Form from '@nubank/nuds-web/components/Form/Form';
import TextField from '@nubank/nuds-web/components/TextField/TextField';
import Box from '@nubank/nuds-web/components/Box/Box';
import Button from '@nubank/nuds-web/components/Button/Button';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

import { useSiteContext } from '../SiteContext/SiteContext';
import { emailOnHeroRegisterEvent } from '../../screens/Registration/tracking';
import { ERROR_SEVERITY, sentryException } from '../../utils/sentry';
import { getDiscoveryUrls } from '../../domains/discoveryUrls/discoveryUrls';

const StyledTextField = styled(TextField)`
  background-color: ${props => (props.inputTransparentBackground ? '#fff3' : 'white')};
  border-bottom: none;
  border-radius: 6.25rem;
  box-shadow: ${props => (props.inputBoxShadow)};
  caret-color: ${props => (props.inputLabelColor)};
  color: ${props => (props.inputLabelColor)};
  height: 3rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;

  &::placeholder {
    color: ${props => (props.inputLabelColor)};
    font-size: 14px;
    position:relative;
    text-align: center;
    top:20%;
    transform:translateY(-50%); 
  }

  &.red-error {
    &::placeholder {
      color: #D72923;
    }

    p {
      display: none;
    }
  }

  :invalid, :valid, :-webkit-autofill, :autofill {
    background-color: ${props => (props.inputTransparentBackground ? '#fff3' : 'white')};
    border:  none;
    border-radius: 6.25rem;
    box-shadow: ${props => (props.inputBoxShadow)};
    caret-color: ${props => (props.inputLabelColor)};
    height: 3rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  :user-invalid {
    border: 2px solid #D72923;
  }
`;

const StyledBox = styled(Box)`
  span, p {
    display: none;
  }

  p#error-email {
    display: block;
    color: ${props => (props.errorMessageColor)};
    text-align: center;
  }

  div {
    min-height: auto;
  }
`;

const StyledButton = styled(Button)`
  ${({ buttonGradient }) => (buttonGradient ? `background: ${buttonGradient};` : '')}
  background-color: ${props => (props.buttonBackgroundColor)};
  color: ${props => (props.buttonLabelColor)};
  display: flex;
  justify-content: center;
`;

const StyledTypography = styled(Typography)`
  /* stylelint-disable property-no-vendor-prefix */
  background: ${props => (props.ctaGradient === 'default' ? props.color : props.ctaGradient)};
  color: ${props => (props.color)};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

function FormBox(props) {
  const {
    buttonBackgroundColor,
    buttonGradientColor,
    buttonLabel,
    buttonLabelColor,
    buttonStyleVariant,
    ctaColor,
    ctaFontSize,
    ctaGradient,
    ctaHide,
    ctaLabel,
    ctaMarginBottom,
    ctaMaxWidth,
    inputAndButtonWidth,
    inputBoxShadow,
    inputLabel,
    inputLabelColor,
    inputTransparentBackground,
    errorMessageColor,
  } = props;

  const { formatMessage } = useIntl();
  const [placeholder, setPlaceholder] = useState(inputLabel);

  const {
    setEmail,
    openRegistrationForm,
    emailInputFormBox,
    updateIsFormBoxAvailable,
    discoveryUrlsList,
    updateDiscoveryUrlsList,
  } = useSiteContext();

  updateIsFormBoxAvailable(true);

  const handleOnClick = () => {
    setPlaceholder('FORMBOX.INPUT.EMPTY.VALIDATION_MESSAGE');
    const placeholders = document.querySelectorAll('#email-on-hero');

    placeholders.forEach(element => {
      element.classList.add('red-error');
    });
  };

  const handleSubmit = async ({
    values,
    setSubmitting,
  }) => {
    if (values.email) {
      try {
        setEmail(values.email);
        openRegistrationForm();
        if (!discoveryUrlsList) {
          const discoveryUrls = await getDiscoveryUrls();
          updateDiscoveryUrlsList(discoveryUrls);
        }
      } catch (error) {
        sentryException({
          error,
          flow: 'application_flow',
          checkpoint: 'set email and open registration form',
          namespace: '<FormBox/>',
          level: ERROR_SEVERITY.CRITICAL,
        });
      } finally {
        setSubmitting(false);

        emailOnHeroRegisterEvent();
      }
    } else {
      setSubmitting(false);
    }
  };

  return (
    <Box width="fit-content">
      <Form>
        <Form.Step
          initialValues={{ email: '' }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {() => (
            <Box
              display="flex"
              flexDirection="column"
              alignItems={{ xs: 'center', lg: 'flex-start' }}
            >
              {ctaHide ? null : (
                <StyledTypography
                  intlKey={ctaLabel}
                  variant={ctaFontSize}
                  color={ctaColor}
                  ctaGradient={ctaGradient}
                  marginBottom={ctaMarginBottom}
                  marginTop={{ xs: '0', md: '4x', lg: '0' }}
                  textAlign={{ xs: 'center', lg: 'left' }}
                  maxWidth={ctaMaxWidth}
                  strong
                />
              )}

              <Box
                maxWidth="335px"
                minWidth="250px"
                width={inputAndButtonWidth}
              >
                <StyledBox
                  width="100%"
                  errorMessageColor={errorMessageColor}
                >

                  <StyledTextField
                    id="email-on-hero"
                    type="email"
                    name="email"
                    label=""
                    placeholder={formatMessage({ id: placeholder })}
                    syncValidations={{
                      required: formatMessage({ id: 'FORMBOX.DEFAULT.INPUT.VALIDATION_MESSAGE' }),
                      email: formatMessage({ id: 'FORMBOX.DEFAULT.INPUT.VALIDATION_MESSAGE' }),
                    }}
                    ref={emailInputFormBox}
                    inputLabelColor={inputLabelColor}
                    inputBoxShadow={inputBoxShadow}
                    inputTransparentBackground={inputTransparentBackground}
                  />
                </StyledBox>

                <StyledButton
                  id="email-on-hero-submit-btn"
                  extended
                  type="submit"
                  intlKey={buttonLabel}
                  variant="contained"
                  styleVariant={buttonStyleVariant}
                  buttonBackgroundColor={buttonBackgroundColor}
                  buttonLabelColor={buttonLabelColor}
                  buttonGradient={buttonGradientColor}
                  iconProps={{
                    name: 'arrow-right',
                    title: formatMessage({ id: `${buttonLabel}` }),
                  }}
                  onClick={() => handleOnClick()}
                />

              </Box>

            </Box>
          )}
        </Form.Step>
      </Form>
    </Box>
  );
}

FormBox.defaultProps = {
  buttonBackgroundColor: 'default',
  buttonLabel: 'FORMBOX.DEFAULT.BUTTON_LABEL',
  buttonLabelColor: 'default',
  buttonGradientColor: 'default',
  buttonStyleVariant: 'primary',
  ctaColor: 'white',
  ctaFontSize: 'heading4',
  ctaGradient: 'default',
  ctaHide: false,
  ctaLabel: 'FORMBOX.DEFAULT.CTA_LABEL',
  ctaMarginBottom: '2x',
  ctaMaxWidth: 'auto',
  inputAndButtonWidth: '100%',
  inputBoxShadow: '0 0 0',
  inputLabel: 'FORMBOX.DEFAULT.INPUT_LABEL',
  inputLabelColor: 'default',
  inputTransparentBackground: false,
  errorMessageColor: '#FFD8D5',
};

FormBox.propTypes = {
  buttonBackgroundColor: PropTypes.string,
  buttonGradientColor: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonLabelColor: PropTypes.string,
  buttonStyleVariant: PropTypes.string,
  ctaColor: PropTypes.string,
  ctaFontSize: PropTypes.string,
  ctaGradient: PropTypes.string,
  ctaHide: PropTypes.bool,
  ctaLabel: PropTypes.string,
  ctaMarginBottom: PropTypes.string,
  ctaMaxWidth: PropTypes.string,
  errorMessageColor: PropTypes.string,
  inputAndButtonWidth: PropTypes.string,
  inputBoxShadow: PropTypes.string,
  inputLabel: PropTypes.string,
  inputLabelColor: PropTypes.string,
  inputTransparentBackground: PropTypes.bool,
};

export default FormBox;

